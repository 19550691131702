import React, { useContext } from 'react';
import Container from '../../component/Container';
import { MainContext } from '../../contaxt/Context';
import SofaBanner from '../../component/SofaBanner';
import Share from "../../component/Share";


const Wfh = () => {
    const cart = [
        {
            image: "image/sofa/vero.jpg",
            name: "HOF - Premium Fabric Sofa - Vero",
        },
        {
            image: "image/sofa/victa.jpg",
            name: "Premium Fabric Sofa - Victa",
        }
       
        ,{
            image: "image/sofa/napoli.jpg",
            name: "HOF - Premium Fabric Sofa - Napoli",
        },
        
        {
            image: "image/sofa/serio.jpg",
            name: "HOF - Premium Fabric Sofa - Serio", 
        },
        {
            image: "image/sofa/cosimo.jpg",
            name: "Premium Fabric Sofa - Cosimo",
        },
        {
            image: "image/sofa/cenna.jpg",
            name: "Premium Fabric Sofa - CENNA",
        },{
            image: "image/sofa/viva.jpg",
            name: "Premium Fabric  - viva ",
        },
        {
            image: "image/sofa/vela.jpg",
            name: "Premium Fabric  -  vela",
        }, {
            image: "image/sofa/brioso.jpg",
            name: "Premium Fabric -brioso",
        },{
            image: "image/sofa/basico.jpg",
            name: "Premium Fabric -brioso +",
        },{
            image: "image/sofa/porto.jpg",
            name: "Premium Fabric -porto",
        },{
            image: "image/sofa/bellow.jpg",
            name: "Premium Fabric -bello",
        },{
            image: "image/sofa/10.jpg",
            name: "eleganzo",
        },{
            image: "image/sofa/11.jpg",
            name: "figuro",
        },{
            image: "image/sofa/12.jpg",
            name: "redefino",
        },{
            image: "image/sofa/13.jpg",
            name: "retro",
        },{
            image: "image/sofa/14.jpg",
            name: "nautica",
        }
    ]
    const { handleWhatsAppClick,handleShareClick } = useContext(MainContext)
    return (
        <>
        <Container  extraclas={'my-8 mt-[100px] p-3'}>
            <div className='grid grid-cols-2 md:grid-cols-4  gap-4 justify-center mb-5'>
                {
                    cart.map((data, index) => {
                        return (
                            <div key={index} className='border relative transition duration-300 ease-in-out hover:scale-110 p-2  hover:shadow-lg'>
                                              <Share onClick={handleShareClick} data={data.name}/>

                                <div className='mx-auto h-[90px] max-w-[180px]'><img className='h-[100%]  w-[100%]' src={data.image} alt="" /></div>
                                <div className='text-center'>
                                    <h1 className='text-[#5b5b5f] text-[10px] md:text-[17px] uppercase  font-bold mt-3'>{data.name}</h1>
                                    <button onClick={() => { handleWhatsAppClick(data) }} className="relative text-[10px] my-3 hover:bg-[blue] md:px-7 px-4 md:text-[16px] inline-flex text-white items-center justify-start py-2 overflow-hidden font-semibold  transition-all duration-150 ease-in-out rounded  bg-gray-800 group">
                                      
                                        Buy Now
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

        </Container>
        <SofaBanner/>
        </>
       
    );
}

export default Wfh;
