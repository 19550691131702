import React, { useContext } from 'react';
import Container from '../../component/Container';
import { MainContext } from '../../contaxt/Context';
import Share from "../../component/Share";



const Office = () => {
   const cart=[
    {
        image:"img/exclutive/evoir1.jpg",
        name: "Evoir air H",
    },
    {
        image:"img/exclutive/eviorm.jpg",
        name: "Evoir air M",
    },
    {
        image:'image/office/13.jpg',
        name:'BRI H'
    },{
        image:'image/office/5.jpg',
        name:'BRI M'
    }, {
        image: "image/exclutive/spon h.jpg",
        name: "SPON H",
      
    } , {
        image: "image/exclutive/spon m.jpg",
        name: "SPON M",
    } , {
        image:"img/exclutive/15.jpg",
        name: "AVEON M",
    },{
        image:"img/exclutive/kos h.jpg",
        name: "KOS H",
    },
    
  {
        image:'image/office/11.jpg',
        name:'KOS M'
    },
    {
        image:'image/office/9.jpg',
        name:'TIGO'
    },{
        image:'image/office/12.jpg',
        name:'tres'
    },{
        image: "image/exclutive/9.png",
        name:"TORO- 5006"
       
    },{
        image: "image/office/13.png",
        name:"pri- 7002 n"
       
    },{
        image: "image/office/14.png",
        name:"pri- 7003 n"
       
    },
   ]
   
    const { handleWhatsAppClick,handleShareClick} = useContext(MainContext)
    return (
        <Container  extraclas={'my-8 mt-[100px] p-3'}>
            <div className='grid grid-cols-2 md:grid-cols-4  gap-4 justify-center mb-5'>
                {
                    cart.map((data, index) => {
                        return (
                            <div key={index} className='border relative transition duration-300 ease-in-out hover:scale-110  p-2  hover:shadow-lg'>
                                              <Share onClick={handleShareClick} data={data.name}/>

                                 <div className='mx-auto  h-[130px] max-w-[150px]'><img className='h-[100%] w-[100%]' src={data.image} alt="" /></div>
                                <div className='text-center'>
                                    <h1 className='text-[#5b5b5f] text-[10px] md:text-[17px] uppercase  font-bold mt-3'>{data.name}</h1>
                                    <button onClick={() => { handleWhatsAppClick(data) }} className="relative text-[10px] my-3 hover:bg-[blue] md:px-7 px-4 md:text-[16px] inline-flex text-white items-center justify-start py-2 overflow-hidden font-semibold  transition-all duration-150 ease-in-out rounded  bg-gray-800 group">
                                      
                                        Buy Now
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }


            </div>

        </Container>
    );
}

export default Office;
