import React,{useContext} from 'react';
import Container from '../../component/Container';
import {MainContext} from '../../contaxt/Context';
import Share from "../../component/Share";

const Executive = () => {
    const cart = [
        {
            image: "img/exclutive/9.jpg",
            name: "MAR- 1001",
        },
        {
            image: "img/exclutive/7.jpg",
            name:"MAR- 1002"
           
        },{
            image: "image/exclutive/1.png",
            name: "ECO- 1003",
           
        },
        {
            image: "image/exclutive/2.png",
            name:"ECO- 1004"
           
        }, {
            image: "image/exclutive/3.png",
            name:"MAR- 1005"
           
        },{
            image: "image/exclutive/4.png",
            name:"STABLE H"
           
        },{
            image: "image/exclutive/stable m.png",
            name:"STABLE M"
           
        },
        {
            image: "img/exclutive/14.jpg",
            name:"MAR- 1009"
           
        },{
            image: "img/exclutive/8.jpg",
            name:"MAR- 1011"
           
        },{
            image: "image/exclutive/7.png",
            name:"MAR- 1006"
           
        },{
            image: "img/exclutive/5.jpg",
            name:"MAR- 1012"
           
        },{
            image: "image/exclutive/9.png",
            name:"TORO- 5006"
           
        },{
            image: "image/exclutive/10.png",
            name:"MAR- 1010"
           
        }, {
            image: "image/exclutive/spon h.jpg",
            name: "SPON H",
          
        } , {
            image: "image/exclutive/spon m.jpg",
            name: "SPON M",
        } ,  {
            image:"img/exclutive/15.jpg",
            name: "AVEON M",
        },{
            image:"img/exclutive/eviorm.jpg",
            name: "Evoir M",
        },{
            image:"img/exclutive/evoir1.jpg",
            name: "Evoir H",
        },{
            image:"img/exclutive/kos h.jpg",
            name: "KOS H",
        },{
            image:"img/exclutive/kos m.jpg",
            name: "KOS M",
        }
        
    ]
const {handleWhatsAppClick,handleShareClick} = useContext(MainContext);
 
    return (
        <Container  extraclas={'my-8 mt-[100px] p-3'}>
            <div className='grid grid-cols-2 md:grid-cols-4  gap-4 justify-center mb-5'>
                {
                    cart.map((data, index) => {
                        return (
                            <div key={index} className='border relative p-2  hover:shadow-lg'>
                                              <Share onClick={handleShareClick} data={data.name}/>

                                <div className='mx-auto  h-[130px] max-w-[150px]'><img className='h-[100%] transition duration-300 ease-in-out hover:scale-110 w-[100%]' src={data.image} alt="" /></div>
                                <div className='text-center'>
                                    <h1 className='text-[#5b5b5f] text-[10px] md:text-[17px] uppercase  font-bold mt-3'>{data.name}</h1>
                                    <button onClick={() => { handleWhatsAppClick(data) }} className="relative text-[10px] my-3 hover:bg-[blue] md:px-7 px-4 md:text-[16px] inline-flex text-white items-center justify-start py-2 overflow-hidden font-semibold  transition-all duration-150 ease-in-out rounded  bg-gray-800 group">
                                      
                                        Buy Now
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }


            </div>

        </Container>
    );
}

export default Executive;
