import React from 'react';

const Buy = () => {
    return (
        <div className='mt-20'>
            <img src="img/Banner/bulk.jpg" alt="" />
        </div>
    );
}

export default Buy;
