import React from 'react';
import Container from './Container';
const Abouts = () => {
    return (
        <Container extraclas={'px-6  md:px-0 mb-8'}>
           <div className='grid text-justify md:grid-cols-2 mt-10'>
   <div className='md:w-[400px] h-[400px] sm:w-[200px] flex justify-center items-center'>
      <img className='h-[90%]' src="img/gallery/3.jpg" alt="" />
   </div>
   <div className='flex px-2 flex-col gap-3'>
      <p className='text-lg text-[#000]'>Kwirky Furniture</p>
      <hr />
      <p className='text-sm text-[black]'>Kwirky is one of the multi-brand organizations in India. It provides proper guidance to the customers for project planning and shows what will be unique and luxurious based on the current visualization.
         "Kwirky is a collaboration of leading brands in India."
         Our amenable staff provides ideas from these multi-company channels, and our duty is to assist clients and ensure product quality. These companies are named HOF Furniture System, Seating Craft Recliners, MT Group Pots, etc. and manufacturing units in Ahmedabad, Delhi, Surat, and Mumbai.
      </p>
      <p className='text-sm text-[black]'>These companies are engaged in manufacturing and supplying a vast array of quality-approved chairs, recliners, sofas, and tables to the global market. All four companies have nearly 40 years of experience, ensuring that all products are thoroughly tested and feature proper ergonomic designs.
      </p>
   </div>
</div>

<div className='grid text-justify md:grid-cols-2 mt-10'>
    <div className='flex px-2 flex-col gap-3'>
        <p className='text-lg text-[#000]'>Kwirky Furniture</p>
        <hr />
        <p className='text-sm text-[black]'>According to Kwirky's principles, we don't operate as distributors or dealers in the market. Our work is highly recognized in the industry for its comfort and stylish designs, and our products are in high demand.
        We focus on the destination, not the difficulties. Our entire team is enthusiastic and full of energy. We take great pride in providing customer service that is not just good but legendary.</p>
        <p className='text-sm text-[black]'>Healthy Seating Instructions
        Prolonged sitting in an office chair can increase stress on the back, neck, shoulders, arms, and legs, and can put pressure on the spinal discs. That's why it's important to use an ergonomic chair, which can provide back support and encourage good posture. Even if you have an ergonomic chair, here are some important guidelines for maximizing your chair's effectiveness.
        ELBOW ANGLE: Sit comfortably close to your desk so that your upper arms are parallel to your spine. Rest your hands on your work surface—if your elbows are not at a 90-degree angle, adjust your chair accordingly.</p>
    </div>
    <div className='md:w-[400px] h-[400px] md:ml-20 sm:w-[200px] '>
        <img className='h-[100%]' src="image/4.jpg" alt="" />
        <div className='text-[12px] text-[black] font-bold capitalize'>Directors: Manoj Jangid & Akhil Kaushik, Kwirky Furniture</div>
    </div>
</div>

<div className='grid text-justify md:grid-cols-2 mt-10'>
    <div className='md:w-[400px] h-[400px] sm:w-[200px] flex justify-center items-center'>
    <img className='h-[100%]' src="img/assets/6.jpg" alt="" />
    </div>
    <div className='flex px-2 flex-col gap-3'>
        <p className='text-lg text-[#000]'>Healthy Seating Instructions</p>
        <hr />
        <p className='text-sm text-[black]'>ARMREST: Adjust the armrest of your office chair so that it slightly lifts your arms at the shoulders. Using an armrest on your office chair is important to alleviate strain on your neck and shoulders, making you less likely to slouch forward in your chair.</p>
        <p className='text-sm text-[black]'>Finally, always remember to stand, stretch, and walk for at atleast a minute or two every half hour. Even minimal movement, such as walking to the water cooler or bathroom, will help keep your joints, ligaments, muscles, and tendons loose, promoting an overall feeling of comfort, relaxation, and improved focus.</p>
        <p className='text-sm text-[black]'>LOW BACK SUPPORT: Ensure your buttocks are pressed against the back of your chair, and there should be a cushion that causes your lower back to arch slightly, preventing you from slumping forward or slouching down in the chair as you tire over time.</p>
        <p className='text-sm text-[black]'>RESTING EYE LEVEL: Close your eyes while sitting comfortably with your head facing forward. Slowly open your eyes. Your gaze should be aimed at the center of your computer screen. If your computer screen is higher or lower than your gaze, adjust it to reduce neck strain.</p>
    </div>
</div>




        </Container>
    );
}
export default Abouts;
