import React, { useContext } from 'react';
import Container from './Container';
import { MainContext } from '../contaxt/Context';
import Share from "../component/Share";

const Cart = () => {
    const cart = [
        {
            image: "img/homechair/4.jpg",
            name: "VITA 1001",
        },
        
        {
            image: "img/homechair/1.jpg",
            name: "VITA 1002",
        },
        {
            image: "img/homechair/vita1004.jpg",
            name: "VITA 1004",
        },{
            image: "img/homechair/vita1005.jpg",
            name: "VITA 1005",
        },
        
        {
            image: "img/homechair/vita1006.png",
            name: "VITA 1006",
        }, {
            image: "img/homechair/vita3001.jpg",
            name: "VITA 3001",
        },
        {
            image: "img/homechair/3.jpg",
            name: "VITA 3002",
        },
         {
            image: "img/homechair/2.jpg",
            name: "VITA 2001",
        },
        {
            image: "img/master/2.jpg",
            name: "Chair - Jalsa",
        },
        {
            image: "img/master/1.jpg",
            name: "Chair Aaram",
        }
      
        
        
    ]
    console.log(cart)
    const { handleWhatsAppClick ,handleShareClick} = useContext(MainContext)
    return (
        <Container extraclas={'my-8 p-3 '}>
            <div className='grid grid-cols-2 md:grid-cols-4  gap-4 justify-center mb-5'>
                {
                    cart.map((data, index) => {
                        return (
                            <div key={index} className='border relative transition duration-300 ease-in-out hover:scale-110 p-2  hover:shadow-lg'>
                                              <Share onClick={handleShareClick} data={data.name}/>

                                <div className='mx-auto  h-[130px] w-[110px]'><img className='h-[100%] w-[100%]' src={data.image} alt="" /></div>
                                <div className='text-center'>
                                    <h1 className='text-[#5b5b5f] text-[10px] md:text-[17px] uppercase  font-bold mt-3'>{data.name}</h1>
                                    <button onClick={() => { handleWhatsAppClick(data) }} className="relative text-[10px] my-3 hover:bg-[blue] md:px-7 px-4 md:text-[16px] inline-flex text-white items-center justify-start py-2 overflow-hidden font-semibold  transition-all duration-150 ease-in-out rounded  bg-gray-800 group">

                                        Buy Now
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }


            </div>
        </Container>
    );
}
export default Cart;
