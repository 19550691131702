import React from 'react';
import Container from './Container';

const Effect = () => {
    return (
        <Container fluid={true} extraclas={'bg-[#e5e7eb] mt-[60px] p-2'}>
            <div className='md:flex  flex-row md:justify-between 
             '>
              <div className='flex  justify-center'>  <img style={{ boxShadow: ' inset rgba(0, 0, 0, 0.0) 0px 10px 30px' }} className='shadow max-w-[320px]  p-8' src="img/gif/2.gif" alt="" /></div>
            <div  className='flex mt-4 md:mt-0 justify-center'> <img  style={{ boxShadow: ' inset rgba(0, 0, 0, 0.0) 0px 30px 90px' }} className='max-w-[320px] shadow  p-8' src="img/gif/6.gif" alt="" /></div>   
             <div  className='flex mt-4 md:mt-0   justify-center'> <img style={{ boxShadow: ' inset rgba(0, 0, 0, 0.0) 0px 30px 90px' }} className='max-w-[320px] shadow  p-8' src="img/gif/7.gif" alt="" /></div>  
            </div>

        </Container>
    );
}

export default Effect;
