import React from 'react';
import Container from '../component/Container';

const MyGallery = () => {
    return (
        <Container fluid={true} extraclas={'mt-[95px]'}>
          <div className='grid grid-cols-2 md:grid-cols-3 bg-[#c5c0b4]'>
          <img src="image/gallery/1.jpg" alt="" />
          <img src="image/gallery/2.jpg" alt="" />
          <img src="image/gallery/3.jpg" alt="" />
          <img src="image/gallery/4.jpg" alt="" />
          <img src="image/gallery/5.jpg" alt="" />
          <img src="image/gallery/7.jpg" alt="" />
          <img src="image/gallery/8.jpg" alt="" />
          <img src="image/gallery/9.jpg" alt="" />
          <img src="image/gallery/10.jpg" alt="" />
          <img src="image/gallery/11.jpg" alt="" />
          <img src="image/gallery/12.jpg" alt="" />
          <img className='md:h-[280px] h-[95px] w-full' src="image/gallery/6.jpg" alt="" />
          </div>
        </Container>
    );
}

export default MyGallery;
