import React, { useContext } from 'react';
import Container from '../../component/Container';
import { MainContext } from '../../contaxt/Context';
import Share from "../../component/Share";



const Center_Table = () => {
    const cart = [
       {
        image: "image/table/center_table/009.jpg",
        name: "009",
    },{
        image: "image/table/center_table/16.jpg",
        name: "016",
    },
    {
        image: "image/table/center_table/058.jpg",
        name: "058",
    },{
        image: "image/table/center_table/812.jpg",
        name: "812",
    },{
        image: "image/table/center_table/815set.jpg",
        name: "815 set",
    },{
        image: "image/table/center_table/mi22.jpg",
        name: "m122",
    },{
        image: "image/table/center_table/SC024.jpg",
        name: "sc024",
    },{
        image: "image/table/center_table/sc036.jpg",
        name: "sc036",
    },{
        image: "image/table/center_table/y07.jpg",
        name: "y07",
    },{
        image: "image/table/center_table/817.jpg",
        name: "817",
    },{
        image: "image/table/center_table/k3i.jpg",
        name: "k31",
    },{
        image: "image/table/center_table/p19.jpg",
        name: "p19",
    },{
        image: "image/table/center_table/sc075.jpg",
        name: "sc075",
    },{
        image: "image/table/center_table/sc110.jpg",
        name: "sc110",
    },{
        image: "image/table/center_table/sc114.jpg",
        name: "sc114",
    },{
        image: "image/table/center_table/sc0127.jpg",
        name: "sco127",
    },{
        image: "image/table/center_table/v0iset.jpg",
        name: "voi set",
    },{
        image: "image/table/center_table/903b.jpg",
        name: "903b",
    }
    ,
    {
        image: "image/table/center_table/sc005.jpg",
        name: "sc005",
    },
    {
        image: "image/table/center_table/7328.jpg",
        name: "7328",
    },  {
        image: "image/table/center_table/905b.jpg",
        name: "905b",
    },
     {
        image: "image/table/center_table/1815b.jpg",
        name: "1815b",
    },
    {
        image: "image/table/center_table/1703 rect.jpg",
        name: "1703(rectangle)",
    },{
        image: "image/table/center_table/1703.jpg",
        name: "1703(square)",
    },{
        image: "image/table/center_table/g35.jpg",
        name: "g35",
    },{
        image: "image/table/center_table/k12.jpg",
        name: "k12",
    },{
        image: "image/table/center_table/k30.jpg",
        name: "k30",
    }
   ]
    const {handleWhatsAppClick,handleShareClick} = useContext(MainContext)
    return (
        <Container  extraclas={'my-8 mt-[100px] p-4'}>
            <div className='grid grid-cols-2 md:grid-cols-4  gap-4 justify-center mb-5'>
                {
                    cart.map((data, index) => {
                        return (
                            <div key={index} className='border relative p-2  hover:shadow-lg'>
              <Share onClick={handleShareClick} data={data.name}/>

                                <div className='mx-auto transition duration-300 ease-in-out hover:scale-110 h-[140px] max-w-[220px]'><img className='h-[100%] w-[100%]' src={data.image} alt="" /></div>
                                <div className='text-center'>
                                    <h1 className='text-[#5b5b5f] text-[10px] md:text-[17px] uppercase  font-bold mt-3'>{data.name}</h1>
                                    <button onClick={() => { handleWhatsAppClick(data) }} className="relative text-[10px] my-3 hover:bg-[blue] md:px-7 px-4 md:text-[16px] inline-flex text-white items-center justify-start py-2 overflow-hidden font-semibold  transition-all duration-150 ease-in-out rounded  bg-gray-800 group">
                                      
                                        Buy Now
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }


            </div>

        </Container>
    );
}

export default Center_Table;
