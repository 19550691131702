import React, { useContext } from 'react';
import Container from '../../component/Container';
import { MainContext } from '../../contaxt/Context';
import Share from "../../component/Share";


const Mt = () => {
    const cart = [
        {
            image: "img/mt/Kwirky Parallelogram FRP Pots-04.png",
            name: "Mt 1010/11",

        },
        {
            image: "img/mt/Kwirky Parallelogram FRP Pots-05.png",
            name: "Mt 1012",

        }
        , {
            image: "img/mt/Kwirky Parallelogram FRP Pots-06.png",

            name: "Mt 1013/14",

        },
        {
            image: "img/mt/Kwirky Parallelogram FRP Pots-07.png",

            name: "Mt 1015/16",

        }, {
            image: "img/mt/Kwirky Parallelogram FRP Pots-08.png",

            name: "Mt 1017/18",

        },
        {
            image: "img/mt/Kwirky Parallelogram FRP Pots-09.png",

            name: "Mt 1019",

        }, {
            image: "img/mt/Kwirky Parallelogram FRP Pots-10.png",

            name: "Mt 1020",

        }, {
            image: "img/mt/Kwirky Parallelogram FRP Pots-11.png",

            name: "Mt 1021",

        }, {
            image: "img/mt/Kwirky Parallelogram FRP Pots-12.png",

            name: "Mt 1022/23",

        }, {
            image: "img/mt/Kwirky Parallelogram FRP Pots-13.png",

            name: "Mt 1024/25",


        }, {
            image: "img/mt/Kwirky Parallelogram FRP Pots-14.png",

            name: "Mt 1026/27/28",
        }, {
            image: "img/mt/Kwirky Parallelogram FRP Pots-15.png",

            name: "Mt 1029/30/31/32",


        }, {
            image: "img/mt/Kwirky Parallelogram FRP Pots-16.png",

            name: "Mt 1034/35/36",

        }, {
            image: "img/mt/Kwirky Parallelogram FRP Pots-17.png",
            name: "Mt 1037/38/39",


        }, {
            image: "img/mt/Kwirky Parallelogram FRP Pots-18.png",

            name: "Mt 1040/41/42",

        }, {
            image: "img/mt/Kwirky Parallelogram FRP Pots-19.png",

            name: "Mt 1043/44/45",

        } ,{
            image: "img/mt/Kwirky Parallelogram FRP Pots-20.png",

            name: "Mt 1046/47/48",

        } ,{
            image: "img/mt/Kwirky Parallelogram FRP Pots-21.png",

            name: "Mt 1049/50/51",

        } ,{
            image: "img/mt/Kwirky Parallelogram FRP Pots-22.png",

            name: "Mt 1052/53",

        } ,{
            image: "img/mt/Kwirky Parallelogram FRP Pots-23.png",

            name: "Mt 1054/55",

        },{
            image: "img/mt/Kwirky Parallelogram FRP Pots-24.png",

            name: "Mt 1056/57/58",

        },{
            image: "img/mt/Kwirky Parallelogram FRP Pots-25.png",

            name: "Mt 1059/60",

        },{
            image: "img/mt/Kwirky Parallelogram FRP Pots-26.png",

            name: "Mt 1061",

        },{
            image: "img/mt/Kwirky Parallelogram FRP Pots-27.png",
            name: "Mt 1062/63/64",

        }
    ]
    const {handleWhatsAppClick,handleShareClick} = useContext(MainContext)
    return (
        <Container  extraclas={'my-8 mt-[100px] p-3'}>
              <div className='grid grid-cols-2 md:grid-cols-4  gap-4 justify-center mb-5'>
                {
                    cart.map((data, index) => {
                        return (
                            <div key={index} className='border p-2  relative hover:shadow-lg'>
                                              <Share onClick={handleShareClick} data={data.name}/>

                                <div className='mx-auto transition duration-300 ease-in-out hover:scale-110 h-[140px] max-w-[220px]'><img className='h-[100%] w-[100%]' src={data.image} alt="" /></div>
                                <div className='text-center'>
                                    <h1 className='text-[#5b5b5f] text-[10px] md:text-[17px] uppercase  font-bold mt-3'>{data.name}</h1>
                                    <button onClick={() => { handleWhatsAppClick(data) }} className="relative text-[10px] my-3 hover:bg-[blue] md:px-7 px-4 md:text-[16px] inline-flex text-white items-center justify-start py-2 overflow-hidden font-semibold  transition-all duration-150 ease-in-out rounded  bg-gray-800 group">
                                      
                                        Buy Now
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }


            </div>

        </Container>
    );
}

export default Mt;
