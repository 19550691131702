import React from 'react';
import {
  BiCurrentLocation, BiLogoWhatsapp
} from "react-icons/bi"
import Container from './Container';
import { MdAddCall } from "react-icons/md";


const Contact = () => {
  return (
    <Container>
      <div className="container p-6 my-2 mx-auto md:px-6">
        <section className="mb-10">
          <div
            className="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
            <div className="flex flex-wrap items-center">
              <div className="block w-full shrink-0 grow-0 basis-auto lg:flex lg:w-6/12 xl:w-4/12">
                <div className="h-[500px] w-full">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28474.15356655467!2d75.74201373450181!3d26.863187107278474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db5b6d412a7a9%3A0x3f2f679d0b86a001!2sKwirky%20Furniture%20-%20Hof%20Zone%20Exclusive%20Showroom%2C%20Seating%20Craft%20Recliners!5e0!3m2!1sen!2sin!4v1695633590223!5m2!1sen!2sin" className="left-0 top-0 sm:p-4 md:p-0 h-full w-full rounded-t-lg lg:rounded-tr-none lg:rounded-bl-lg" frameborder="0"
                    allowfullscreen ></iframe>
                </div>
              </div>
              <div className="w-full shrink-0 grow-0 basis-auto lg:w-6/12 xl:w-8/12">
                <div className="flex flex-wrap px-3 pt-12 pb-12 md:pb-0 lg:pt-0">
                  <div className="mb-12 w-full shrink-0 grow-0 basis-auto px-3 md:w-6/12 md:px-6 lg:w-full xl:w-6/12 xl:px-12">
                    <div className="flex items-start">
                      <div className="shrink-0">
                        <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                        <MdAddCall  className='text-[40px]'/>

                        </div>
                      </div>
                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold dark:text-white">
                          Contact Us
                        </p>
                        <p className="text-neutral-500 dark:text-neutral-200">
                        kwirky1998@gmail.com
                        </p>
                        <p className="text-neutral-500 dark:text-neutral-200">
                          +91 9351870238
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mb-12 w-full shrink-0 grow-0 basis-auto px-3 md:w-6/12 md:px-6 lg:w-full xl:w-6/12 xl:px-12">
                    <div className="flex items-start">
                      <div className="shrink-0">
                        <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                          <BiCurrentLocation className='text-[40px]' />
                        </div>
                      </div>
                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold dark:text-white">
                          Address
                        </p>
                        <p className="text-neutral-500 dark:text-neutral-200">
                          S-1-A, Gopalpura Bypass Rd, opp. Hotel Grand Safari, Shri Gopal Nagar, Arjun Nagar, Jaipur, Rajasthan 302018
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full shrink-0 grow-0 basis-auto px-3 md:w-6/12 md:px-6 lg:w-full xl:w-6/12 xl:px-12">
                    <div className="align-start flex">
                      <div className="shrink-0">
                        <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                          <BiLogoWhatsapp className='  text-[40px]' />
                        </div>
                      </div>
                      <div className="ml-6 grow">

                        <a href="https://api.whatsapp.com/send/?phone=9351870238&text=Hello%2C+I+am+interested+in your services">
                          <p className="mb-2 font-bold dark:text-white">ChatNow</p>
                          <p className="text-neutral-500 dark:text-neutral-200">
                          +919351870238
                          </p>
                        </a>
                        <p className="text-neutral-500 dark:text-neutral-200">
                          +919829334894
                          </p>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
          </div>
        </section>
      </div>
    </Container>

  );
}

export default Contact;
