import React from 'react';
import Container from './Container';

const Banner = () => {
    return (
        <Container  fluid={true}>
<div>
    <img src="img/hof-india-banner.jpg" alt="" />
</div>
        </Container>
    );
}

export default Banner;
