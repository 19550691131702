import React, { useContext } from 'react';
import Container from '../../component/Container';
import { MainContext } from '../../contaxt/Context';
import Share from "../../component/Share";



const Cafe_Chair = () => {
    const cart = [
        {
           image: "image/table/cafe/allsesion.jpg",
           name: "all season",
       }, {
        image: "image/table/cafe/am.jpg",
        name: "a-m",
    }, {
        image: "image/table/cafe/bar table.jpg",
        name: "bar table",
    }, {
        image: "image/table/cafe/f43.jpg",
        name: "f-43",
    },{
        image: "image/table/cafe/milano glass round.jpg",
        name: "milano glass round",
    },{
        image: "image/table/cafe/milano glass square.jpg",
        name: "milano glass square",
    },{
        image: "image/table/cafe/milano.jpg",
        name: "milano",
    },{
        image: "image/table/cafe/nm.jpg",
        name: "n-m",
    },{
        image: "image/table/cafe/sd103.jpg",
        name: "sd-103",
    },{
        image: "image/table/cafe/sd152.jpg",
        name: "sd-152",
    },{
        image: "image/table/cafe/t18.jpg",
        name: "t-18",
    },{
        image: "image/table/cafe/t19.jpg",
        name: "T-19",
    },{
        image: "image/table/cafe/z218.jpg",
        name: "z-218",
    },{
        image: "image/table/cafe/z231.jpg",
        name: "z-231",
    },{
        image: "image/table/cafe/z232.jpg",
        name: "z-232",
    },{
        image: "image/table/cafe/z267.jpg",
        name: "z-267",
    },{
        image: "image/table/cafe/4start.jpg",
        name: "s star",
    },{
        image: "image/table/cafe/322.jpg",
        name: "322",
    },{
        image: "image/table/cafe/1703.jpg",
        name: "i703",
    },{
        image: "image/table/cafe/6055.jpg",
        name: "605s",
    },{
        image: "image/table/cafe/hamini.jpg",
        name: "gemini table",
    },{
        image: "image/table/cafe/z230.jpg",
        name: "z-230",
    },{
        image: "image/table/cafe/z293.jpg",
        name: "z-293",
    },{
        image: "image/table/cafe/4012.jpg",
        name: "4012",
    },
    {
        image: "image/table/center_table/sdh05.jpg",
        name: "sdh05",
    },
   ]
    const {handleWhatsAppClick,handleShareClick} = useContext(MainContext)
    return (
        <Container  extraclas={'my-8 mt-[100px] p-3'}>
            <div className='grid grid-cols-2 md:grid-cols-4  gap-4 justify-center mb-5'>
                {
                    cart.map((data, index) => {
                        return (
                            <div key={index} className='border relative overflow-hidden transition duration-300 ease-in-out hover:scale-110 P-2  hover:shadow-lg'>
                                 <Share onClick={handleShareClick} data={data.name}/>
                                <div className='mx-auto  h-[140px] max-w-[140px]'><img className='h-[100%] w-[100%]' src={data.image} alt="" /></div>
                                <div className='text-center mt-2 md:mt-4'>
                                    <h1 className='text-[#5b5b5f] text-[10px] md:text-[17px] uppercase  font-bold'>{data.name}</h1>
                                    <button onClick={() => { handleWhatsAppClick(data) }} className="relative text-[10px] my-3 hover:bg-[blue] md:px-7 px-4 md:text-[16px] inline-flex text-white items-center justify-start py-2 overflow-hidden font-semibold  transition-all duration-150 ease-in-out rounded  bg-gray-800 group">
                                      
                                        Buy Now
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }


            </div>

        </Container>
    );
}

export default Cafe_Chair;
