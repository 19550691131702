import React, { useContext } from "react";
import Container from "../../component/Container";
import { MainContext } from "../../contaxt/Context";
import Share from "../../component/Share";

const Premior = () => {
  const cart = [
    {
      image: "img/siting/Seating Craft_page-0003.jpg",
      name: "Blazy Steel Arm",
    },
    {
      image: "img/siting/Seating Craft_page-0004.jpg",
      name: "Alaska",
    },
    {
      image: "img/siting/Seating Craft_page-0005.jpg",
      name: "Athens",
    },
    {
      image: "img/siting/Seating Craft_page-0006.jpg",
      name: "Arena",
    },
    {
      image: "img/siting/Seating Craft_page-0007.jpg",
      name: "Atlanta",
    },
    {
      image: "img/siting/Seating Craft_page-0008.jpg",
      name: "Blazy",
    },
    {
      image: "img/siting/Seating Craft_page-0009.jpg",
      name: "Elegant",
    },
    {
      image: "img/siting/Seating Craft_page-0010.jpg",
      name: "Elight",
    },
    {
      image: "img/siting/Seating Craft_page-0011.jpg",
      name: "Cegnus",
    },
    {
      image: "img/siting/Seating Craft_page-0012.jpg",
      name: "Elight Super",
    },
    {
      image: "img/siting/Seating Craft_page-0013.jpg",
      name: "Feast",
    },
    {
      image: "img/siting/Seating Craft_page-0014.jpg",
      name: "Feria",
    },
    {
      image: "img/siting/Seating Craft_page-0015.jpg",
      name: "Flori",
    },
    {
      image: "img/siting/Seating Craft_page-0016.jpg",
      name: "Florida",
    },
  ];
  const { handleWhatsAppClick, handleShareClick } = useContext(MainContext);
  return (
    <Container extraclas={"my-8 mt-[100px] p-3"}>
      <div className="grid grid-cols-2 md:grid-cols-4  gap-4 justify-center mb-5">
        {cart.map((data, index) => {
          return (
            <div key={index} className="border p-2 relative hover:shadow-lg">
              <Share onClick={handleShareClick} data={data.name} />

              <div className="mx-auto transition duration-300 ease-in-out hover:scale-110 h-[140px] max-w-[220px]">
                <img className="h-[100%] w-[100%]" src={data.image} alt="" />
              </div>
              <div className="text-center">
                <h1 className="text-[#5b5b5f] text-[10px] md:text-[17px] uppercase  font-bold mt-3">
                  {data.name}
                </h1>
                <button
                  onClick={() => {
                    handleWhatsAppClick(data);
                  }}
                  className="relative text-[10px] my-3 hover:bg-[blue] md:px-7 px-4 md:text-[16px] inline-flex text-white items-center justify-start py-2 overflow-hidden font-semibold  transition-all duration-150 ease-in-out rounded  bg-gray-800 group"
                >
                  Buy Now
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default Premior;
