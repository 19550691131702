import React, { useContext } from "react";
import Container from "../../component/Container";
import { MainContext } from "../../contaxt/Context";
import Share from "../../component/Share";

const Delux = () => {
  const cart = [
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-03.png",
      name: "Royal 24/18/12",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-04.png",
      name: "Tyre 8/9/12",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-05.png",
      name: "Galaxy 14/17/20",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-06.png",
      name: "Diamond 7/10",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-07.png",
      name: "Eggo 13/16",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-08.png",
      name: "Bumpy 10/12/16",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-09.png",
      name: "Turbo 10/13/16/19",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-10.png",
      name: "Mountain 20/29/28/32",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-11.png",
      name: "Rope 10/14/18/26",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-12.png",
      name: "Small Sharpe 18/24/30",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-13.png",
      name: "Crown 13/18/22",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-14.png",
      name: "Flora 10/13/18/21",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-15.png",
      name: "Pool 10/13/18/21",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-16.png",
      name: "Crystal 20/16/13/10",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-17.png",
      name: "Rock 12/15/18/21",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-18.png",
      name: "Ring 16/20",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-19.png",
      name: "Big Shark 18/24/30/40",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-20.png",
      name: "Boom 13/20/24",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-21.png",
      name: "Disc 16/18",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-22.png",
      name: "Smiley 12/20",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-23.png",
      name: "Textare 09/12/15",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-24.png",
      name: "Rrc 08/10/11",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-25.png",
      name: "Boat 10/15/20 ",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-26.png",
      name: "Dew 18/26/30",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-27.png",
      name: "Square Deck 28/29",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-28.png",
      name: "Crown Tex 12/16/22",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-29.png",
      name: "Tower 40/48",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-30.png",
      name: "Rim 10/13",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-31.png",
      name: "Hyper Loop 10/12/15/18",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-32.png",
      name: "Antilia 26/28",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-33.png",
      name: "Spirel 36/34",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-34.png",
      name: "Brick 36/24",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-35.png",
      name: "Meltino 28/35",
    },
    {
      image: "img/mt/royal/Kwirky Deluxe Frp pots-36.png",
      name: "Wallhand A/B/C",
    },
  ];
  const { handleWhatsAppClick,handleShareClick } = useContext(MainContext);
  return (
    <Container extraclas={"my-8 mt-[100px] p-3"}>
      <div className="grid grid-cols-2 md:grid-cols-4  gap-4 justify-center mb-5">
        {cart.map((data, index) => {
          return (
            <div key={index} className="border p-2 relative hover:shadow-lg">
              <Share onClick={handleShareClick} data={data.name} />

              <div className="mx-auto transition duration-300 ease-in-out hover:scale-110 h-[140px] max-w-[220px]">
                <img className="h-[100%] w-[100%]" src={data.image} alt="" />
              </div>
              <div className="text-center">
                <h1 className="text-[#5b5b5f] text-[10px] md:text-[17px] uppercase  font-bold mt-3">
                  {data.name}
                </h1>
                <button
                  onClick={() => {
                    handleWhatsAppClick(data);
                  }}
                  className="relative text-[10px] my-3 hover:bg-[blue] md:px-7 px-4 md:text-[16px] inline-flex text-white items-center justify-start py-2 overflow-hidden font-semibold  transition-all duration-150 ease-in-out rounded  bg-gray-800 group"
                >
                  Buy Now
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default Delux;
