import React, { useState } from 'react';
import Container from './Container';
import { Link } from 'react-router-dom';

const Grandmenu = () => {
    const [isActive, setIsActive] = useState(false);
    const handleMenuClick = () => {
        setIsActive(!isActive);
    };
    return (
        <Container fluid={true}>
            <header className='text-[10px]' style={{ zIndex: '1000' }}>
                <div className="container ">
                    <nav className={isActive ? 'active' : ''}>
                        <div>
                        <div className="menu-icons" onClick={handleMenuClick}>
                            <i className="fas text-black text-3xl fa-bars"></i>
                            <i className="fas text-black text-3xl fa-times"></i>
                        </div>
                        <Link to='/' className='my-4'>
                            <img src="img/assets/logo.png" className="md:cursor-pointer w-18 h-16" alt="" />
                        </Link>
                        </div>
                        <ul class="nav-list">
                            <li className='md:hidden mt-[20px]'>
                                <Link to='' class="logo">
                                    <img src="img/assets/logo.png" className="md:cursor-pointer w-18 h-12" alt="" />
                                </Link>
                            </li>
                            <li>
                                <Link onClick={handleMenuClick} className='text-[10px]' to='/'>Home</Link>
                            </li>
                            <li>
                                <Link className='text-[10px] ' to=''>Siting<i class="fas  fa-caret-down"></i></Link>
                                <ul style={{ width: '12rem' }} class="sub-menu p-2">
                                    <li>
                                     <Link onClick={handleMenuClick} to='/premium_chairs'>PREMIUM CHAIRS</Link>
                                    </li>
                                    <li>
                                 <Link onClick={handleMenuClick} to='/executive_chairs'>EXECUTIVE CHAIRS
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={handleMenuClick} to='/office_chairs'>OFFICE CHAIRS</Link>
                                    </li>
                                    <li>
                              <Link onClick={handleMenuClick} to='/visitor_chairs'>VISITOR CHAIRS</Link>
                                    </li>
                                    <li>
                             <Link onClick={handleMenuClick} to='/students_chair'>STUDENTS CHAIRS</Link>
                                    </li>
                                    <li>
                                        <Link onClick={handleMenuClick} to='/wfh_chairs'>LUXURY SOFAS</Link>
                                    </li>
                                    <li>
                                        <Link onClick={handleMenuClick} to='/lounge_chairs'>LOUNGE CHAIRS</Link>
                                            </li>
                                </ul>
                            </li>

                            <li >
                                <Link href="#">BRAND
                                    <i class="fas fa-caret-down"></i>
                                </Link>
                                <ul style={{ width: '17rem' }} class="sub-menu  p-2" >
                                    <li>
                                        <Link onClick={handleMenuClick} to='/sitingcraft'>SEATING CRAFT RECLINERS</Link>
                                    </li>
                                    <li>
                                        <Link to=''>HOF (FURNITURE & MORE)
                                            <i class="fas fa-caret-down"></i></Link>
                                        <ul style={{ width: '12rem' }} class="sub-menu p-2">
                                            <li>
                                    <Link onClick={handleMenuClick} to='/premium_chairs'>PREMIUM CHAIRS</Link>
                                            </li>
                                            <li>
                                 <Link onClick={handleMenuClick} to='/executive_chairs'>EXECUTIVE CHAIRS
                                                </Link>
                                            </li>
                                            <li>
                                  <Link onClick={handleMenuClick} to='/office_chairs'>OFFICE CHAIRS</Link>
                                            </li>
                                            <li>
                                    <Link onClick={handleMenuClick} to='/visitor_chairs'>VISITOR CHAIRS</Link>
                                            </li>
                                            <li>
                                     <Link onClick={handleMenuClick} to='/students_chair'>STUDENTS CHAIRS</Link>
                                            </li>
                                            <li>
                                    <Link onClick={handleMenuClick} to='/wfh_chairs'>LUXURY SOFAS</Link>
                                            </li><li>
                                     <Link onClick={handleMenuClick} to='/lounge_chairs'>LOUNGE CHAIRS</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to='/udesire'></Link>
                                    </li>
                                    <li>
                                        <Link >importend Series
                                            <i class="fas fa-caret-down"></i></Link>
                                            <ul style={{ width: '14rem' }} class="sub-menu p-2">
                                            
                                            <li>
                                                <Link onClick={handleMenuClick} to='/cafe_chair'>SIDE tables
                                                </Link>
                                            </li>  <li>
                                                <Link onClick={handleMenuClick} to='/center_table'>Center tables
                                                </Link>
                                            </li>                                
                                        
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to=''>MT GROUP
                                            <i class="fas fa-caret-down"></i></Link>
                                            <ul style={{ width: '14rem' }} class="sub-menu p-2">
                                            <li>
                                                <Link onClick={handleMenuClick} to='/delux'>Delux series
                                                </Link>
                                            </li>
                                            <li>
                                                <Link onClick={handleMenuClick} to='/mt'>Parallelogrma series

                                                </Link>
                                            </li>
                                            <li>
                                                <Link onClick={handleMenuClick} to='/precious'>Precious Series
                                                </Link>
                                            </li>

                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link  onClick={handleMenuClick} to='/about'>ABOUT US</Link>
                            </li>
                            <li>
                                <Link  onClick={handleMenuClick} to='/bulk'>BUY IN BULK</Link>
                            </li> 
                            <li>
                                <Link  onClick={handleMenuClick} to='/mygallery'>Gallery</Link>
                            </li>
                            <li>
                                <Link  onClick={handleMenuClick} to='/projects_executed'>Projects Executed</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
        </Container>
    );
}
export default Grandmenu;










