import React, { useContext } from "react";
import Container from "../../component/Container";
import { MainContext } from "../../contaxt/Context";
import Share from "../../component/Share";


const Lounge = () => {
  const cart = [
    {
      image: "image/lounge/Sofa Booklet_compressed_page-1.jpg",
      name: "cali",
    },
    {
      image: "image/lounge/Sofa Booklet_compressed_page-2.jpg",
      name: "aalamb",
    },
    {
      image: "image/lounge/Sofa Booklet_compressed_page-3.jpg",
      name: "upra",
    },
    {
      image: "image/lounge/Sofa Booklet_compressed_page-4.jpg",
      name: "toran",
    },
    {
      image: "image/lounge/Sofa Booklet_compressed_page-5.jpg",
      name: "aaram",
    },
    {
      image: "image/lounge/Sofa Booklet_compressed_page-6.jpg",
      name: "jalsa",
    },
  ];
  const { handleWhatsAppClick, handleShareClick } = useContext(MainContext);

  return (
    <Container extraclas={"my-8 mt-[100px] p-3"}>
      <div className="grid grid-cols-2 md:grid-cols-4  gap-4 justify-center mb-5">
        {cart.map((data, index) => {
          return (
            <div key={index} className="border relative p-2  hover:shadow-lg">
              <div className="mx-auto transition duration-300 ease-in-out hover:scale-110 h-[140px] max-w-[220px]">
              <Share onClick={handleShareClick} data={data.name}/>
                <img className="h-[100%] w-[100%]" src={data.image} alt="" />
              </div>
             
              <div className="text-center">
                <h1 className="text-[#5b5b5f] text-[10px] md:text-[17px] uppercase  font-bold mt-3">
                  {data.name}
                </h1>
                <button
                  onClick={() => {
                    handleWhatsAppClick(data);
                  }}
                  className="relative text-[10px] my-3 hover:bg-[blue] md:px-7 px-4 md:text-[16px] inline-flex text-white items-center justify-start py-2 overflow-hidden font-semibold  transition-all duration-150 ease-in-out rounded  bg-gray-800 group"
                >
                  Buy Now
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default Lounge;
