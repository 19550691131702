import React from 'react';
import Container from './Container';

const sofaBanner = () => {
    return (
        <Container fluid={true}>
<div>
    <img src="img/Banner/1.jpg" alt="" />
</div>
        </Container>
    );
}

export default sofaBanner;
