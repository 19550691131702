import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home'
import Main from './pages/Main'
import Bulk from './pages/Bulk'
import About from './pages/About'
import Premior from './pages/categoris/Premior'
import Executive from './pages/categoris/Executive'
import Office from './pages/categoris/Office'
import Student from './pages/categoris/Student'
import Visitor from './pages/categoris/Visitor'
import Wfh from './pages/categoris/Wfh'
import Siting from './pages/categoris/Siting'
import Mt from './pages/categoris/Mt';
import Delux from './pages/categoris/Delux';
import Precious from './pages/categoris/Precious';
import Lounge from './pages/categoris/Lounge'
import Cafe_Chair from './pages/categoris/Cafe_Chair'
import Center_Table from './pages/categoris/Center_Table';
import ProjectsExecuted from './pages/categoris/Projects_Executed';
import MyGallery from './pages/MyGallery';



const routes = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
    children: [
      {
        path: "",
        element: <Home />
      },
      {
        path: '/about',
        element: <About />
      },
      {
        path: '/bulk',
        element: <Bulk />
      },
      {
        path: '/premium_chairs',
        element: <Premior />
      }, {
        path: '/executive_chairs',
        element: <Executive />
      }, {
        path: '/office_chairs',
        element: <Office />
      }, {
        path: '/visitor_chairs',
        element: <Visitor />
      }, {
        path: '/students_chair',
        element: <Student />
      }, {
        path: '/wfh_chairs',
        element: <Wfh />
      },
      {
        path: '/sitingcraft',
        element: <Siting/>
      }, {
        path: '/mt',
        element: <Mt/>
      },
      {
        path: '/delux',
        element: <Delux/>
      },
      {
        path: '/precious',
        element: <Precious/>
      }, {
        path: '/lounge_chairs',
        element: <Lounge/>
      }, {
        path: '/cafe_chair',
        element: <Cafe_Chair/>
      }, {
        path: '/center_table',
        element: <Center_Table/>
      },{
        path: '/mygallery',
        element: <MyGallery/>
      },{
        path: '/projects_executed',
        element: <ProjectsExecuted/>
      }
    ]
  },

]
)

const App = () => {
  
  return (
    <RouterProvider router={routes} />
  );
}

export default App;
