import React, { useContext } from 'react';
import Container from '../../component/Container';
import { MainContext } from '../../contaxt/Context';
import Share from "../../component/Share";



const Premior = () => {
    const cart = [
        {
            image: "img/premiour/1.jpg",
            name: "KENZO H",
        },
        {
            image: "img/premiour/1.1.jpg",
            name: "KENZO M",
        },
        {
            image: "img/premiour/15.jpg",
            name: "ZELDO H",
        }, {
            image: "img/premiour/14.jpg",
            name: "ZELDO M",
        },{
            image: "img/premiour/MOLTO M.png",
            name: "MOLTO M",
        },{
            image: "img/premiour/25.png",
            name: "MOLTO H",
        },
        {
            image: "img/premiour/5.jpg",
            name: "BOSS H",
        },
        {
            image: "img/premiour/5.1.jpg",
            name: "BOSS M",
        }
        ,
        {
            image: "img/premiour/17.jpg",
            name: "ZYDO H ",
        },
        {
            image: "img/premiour/18.jpg",
            name: "ZYDO M",
        },{
            image: "img/premiour/13.jpg",
            name: "LUZO H",
        },{
            image: "img/premiour/6.jpg",
            name: "LUZO M",
        },{
            image: "img/premiour/20.jpg",
            name: "ZORO H",
        },{
            image: "img/premiour/19.jpg",
            name: "ZORO M",
        },{
            image: "img/premiour/21.jpg",
            name: "ZEBA H",
        },{
            image: "img/premiour/ZEBA M.png",
            name: "ZEBA M",
        },{
            image: "img/premiour/22.jpg",
            name: "ELDO H",
        },{
            image: "img/premiour/23.jpg",
            name: "ELDO M",
        },{
            image: "img/premiour/24.jpg",
            name: "LYON",
        }
    ]
    const {handleWhatsAppClick,handleShareClick} = useContext(MainContext)
    return (
        <Container  extraclas={'my-8 mt-[100px] p-3'}>
            <div className='grid grid-cols-2 md:grid-cols-4  gap-4 justify-center mb-5'>
                {
                    cart.map((data, index) => {
                        return (
                            <div key={index} className='border  relative p-2 transition duration-300 ease-in-out hover:scale-110  hover:shadow-lg'>
                                              <Share onClick={handleShareClick} data={data.name}/>

                                <div className='mx-auto  max-h-[140px] max-w-[150px]'><img className='h-[100%]  w-[100%]' src={data.image} alt="" /></div>
                                <div className='text-center'>
                                    <h1 className='text-[#5b5b5f] text-[10px] md:text-[17px] uppercase  font-bold mt-3'>{data.name}</h1>
                                    <button onClick={() => { handleWhatsAppClick(data) }} className="relative text-[10px] my-3 hover:bg-[blue] md:px-7 px-4 md:text-[16px] inline-flex text-white items-center justify-start py-2 overflow-hidden font-semibold  transition-all duration-150 ease-in-out rounded  bg-gray-800 group">
                                        Buy Now
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }


            </div>

        </Container>
    );
}

export default Premior;
