import React, { useContext } from 'react';
import Container from '../../component/Container';
import { MainContext } from '../../contaxt/Context';
import Share from "../../component/Share";



const Precious = () => {
    const cart = [
       {
            image: "img/mt/kylen/Kwirky Precious Frp pots-03.png",
            name: "Conical 40/33/28/21",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-04.png",
            name: "Kylen 26/22/18/14/10",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-05.png",
            name: "Dean Gelo 20/16/12",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-06.png",
            name: "Ball 18/14/10/8",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-07.png",
            name: "Juniper 30/22/16/11",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-08.png",
            name: "Altana 24/18/12",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-09.png",
            name: "traingle 24/18/12/09",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-10.png",
            name: "Hemispherical 30/24/18",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-11.png",
            name: "Pear 26/20/15",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-12.png",
            name: "Alex 40/32/25/18",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-13.png",
            name: "Hellow 42/34/27/20/13",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-14.png",
            name: "Laffas 42/30/24/15",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-15.png",
            name: "Iris 36/20/24/18",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-16.png",
            name: "Astrid 20",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-17.png",
            name: "Aislinn 26/22/17",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-18.png",
            name: "Atticus 42/30/24",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-19.png",
            name: "Zaylee 36/30/24",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-20.png",
            name: "Hexagonal 30/26/22",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-21.png",
            name: "Brantley 27/20/14",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-22.png",
            name: "Coloson 30/11/24/14",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-23.png",
            name: "Gatlin 14/11",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-24.png",
            name: "zion 22/15/10",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-25.png",
            name: "Easton 30/20/10",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-26.png",
            name: "Plain Traingle 20",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-27.png",
            name: "Aislinn 19/14/12",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-28.png",
            name: "Cylidrical 25/20/15/11",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-29.png",
            name: "Octagonal 7/8/9",

        },{
            image: "img/mt/kylen/Kwirky Precious Frp pots-30.png",
            name: "Arlo 9/10/11",

        }
    ]
    const {handleWhatsAppClick,handleShareClick} = useContext(MainContext)
    return (
        <Container  extraclas={'my-8 mt-[100px] p-3'}>
            <div className='grid grid-cols-2 md:grid-cols-4  gap-4 justify-center mb-5'>
                {
                    cart.map((data, index) => {
                        return (
                            <div key={index} className='border relative overflow-hidden transition duration-300 ease-in-out hover:scale-110 P-2  hover:shadow-lg'>
                                              <Share onClick={handleShareClick} data={data.name}/>

                            <div className='mx-auto  max-h-[140px] max-w-[220px]'><img className='h-[100%]  w-[100%]' src={data.image} alt="" /></div>
                              <div className='text-center'>
                                  <h1 className='text-[#5b5b5f]  md:mt-10 text-[10px] md:text-[17px] uppercase font-bold'>{data.name}</h1>
                                  <button onClick={() => { handleWhatsAppClick(data) }} className="relative text-[10px] my-3 hover:bg-[blue] md:px-7 px-4 md:text-[16px] inline-flex text-white items-center justify-start py-2 overflow-hidden font-semibold  transition-all duration-150 ease-in-out rounded  bg-gray-800 group">
                                      Buy Now
                                  </button>
                              </div>
                          </div>
                        )
                    })
                }


            </div>

        </Container>
    );
}

export default Precious;
