import React from 'react';
import Container from '../../component/Container';
import { FcCallback } from "react-icons/fc"



const ProjectsExecuted = () => {
    const cart = [
        {
            ProjectName: "Motion institute (kota)",
            Image1: "image/projects/Motion institute kota1.jpg",
            Image2: "image/projects/Motion institute kota2.jpg",
          
        },
        {
            ProjectName: "Gkr enterprise ltd (jaipur)",
            Image1: "image/projects/Gkr enterprise ltd Jaipur1.jpg",
            Image2: "image/projects/Gkr enterprise ltd Jaipur2.jpg",
          
        },
        {
            ProjectName: " Jecrc college & university (jaipur)",
            Image1: "image/projects/Jecrc college & university Jaipur.jpg", 
             Image2: "image/projects/Jecrc college & university Jaipur2.jpg",
          
        },
        {
       
            ProjectName: "Patoliya Enterprises Sirsi road (jaipur)",
            Image1: "image/projects/Patoliya Enterprises1.jpg",
            Image2: "image/projects/Patoliya Enterprises2.jpg",
          
        },
        {
       
            ProjectName: "saraf textile mills pvt ltd (jaipur)",
            Image1: "image/projects/Saraf textile1.jpg",
            Image2: "image/projects/Saraf textile2.jpg",
          
        },{
       
            ProjectName: "Ezulix software pvt ltd (jaipur)",
            Image1: "image/projects/Ezulix software1.jpg",
            Image2: "image/projects/Ezulix software1.jpg",
          
        },
        {
       
            ProjectName: "Nawal book Depo ,riddhi siddhi (jaipur)",
            Image1: "image/projects/Nawal book Depo1.jpg",
            Image2: "image/projects/Nawal book Depo2.jpg",
          
          
        },{
       
            ProjectName: "Raj Appareals  Mansarovar (jaipur)",
            Image1: "image/projects/Raj Appareals 1.jpg",
            Image2: "image/projects/Raj Appareals 2.jpg",
          
          
        },{
       
            ProjectName: "Ginni Memorial Hospital, (jaipur)",
            Image1: "image/projects/Ginni Memorial Hospital.jpg",
            Image2: "image/projects/Ginni Memorial Hospital.jpg",
          
          
        }
        ,{
       
            ProjectName: "Gems park jewels (jaipur) ",
            Image1: "image/projects/Gems park jewels.jpg",
            Image2: "image/projects/Gems park jewels.jpg",
          
          
        },
        {
       
            ProjectName: "ATCS , Mahendra Sez (jaipur)",
            Image1: "image/projects/ATCS , Mahendra Sez.jpg",
            Image2: "image/projects/ATCS , Mahendra Sez.jpg",
          
          
        },
        {
       
            ProjectName: "Vardhman Group (jaipur)",
            Image1: "image/projects/Vardhman Group.jpg",
            Image2: "image/projects/Vardhman Group.jpg",
          
          
        },
        {
       
            ProjectName: "kothari house c scheme (jaipur)",
            Image1: "image/projects/khotari house 1.jpg",
            Image2: "image/projects/khotari house 2.jpg",
          
          
        },
        {
       
            ProjectName: "DG OFFICE - police headquarter (jaipur)",
            Image1: "image/projects/DG OFFICE - police headquarter.jpg",
            Image2: "image/projects/DG OFFICE - police headquarter.jpg",
          
          
        },{
       
            ProjectName: "B. R. Public School (sikar)",
            Image1: "image/projects/B.R School.jpg",
            Image2: "image/projects/B.R School.jpg",
          
          
        },{
       
            ProjectName: "CLC institute (sikar)",
            Image1: "image/projects/CLC institute sikar.jpg",
            Image2: "image/projects/CLC institute sikar2.jpg",
          
          
        },{
       
            ProjectName: "Gurukripa institute (sikar)",
            Image1: "image/projects/Gurukripa institute sikar.jpg",
            Image2: "image/projects/Gurukripa institute sikar1.jpg",
          
          
        },{
       
            ProjectName: "Delhi trading corpo. Mahi. sez (jaipur)",
            Image1: "image/projects/delhi1.jpg",
            Image2: "image/projects/delhi2.jpg",
          
          
        },
       
       
    ]
    return (
      <Container fluid={true} extraclas={'mt-[100px] mb-4'}>
        {
cart.map((data,index)=>{
  return <div key={index} className='mb-2 bg-[white] shadow'>
    
     <div className='text-gray-900  py-2.5 text-center'>
    <h1 style={{borderBottom:"2px solid black"}} className='py-2 capitalize font-bold text-[10px] md:text-lg '>project name:{data.ProjectName}</h1>
    <div  style={{borderBottom:"2px solid black"}}  className='flex gap-1 justify-around  p-3 '>
         <img style={{border:"2px solid black"}} className='md:h-[300px] w-[130px]  md:w-[400px] h-[130px] ' src={data.Image1} alt="" />
      <img style={{border:"2px solid black"}} className='md:h-[300px] w-[130px] md:w-[400px] h-[130px]'  src={data.Image2} alt="" />
    </div>
    </div>
    <div className='capitalize cursor-pointer font-bold md:text-lg text-[11px] gap-2 mt-2 md:text-md '>
        <a  href="https://api.whatsapp.com/send/?phone=9351870238&text=Hello%2C+I+am+interested+in your services">
            <div className='flex  justify-center items-center gap-2 '>
            <p>kwirkyfurniture-contact now </p>
    <p ><FcCallback className='text-[20px] animate-bounce'/></p>
            </div>
    </a>
    </div>
  </div>
    
})
        }
      
      
      </Container>
    );
}

export default ProjectsExecuted;
