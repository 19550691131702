import React from 'react';
import Abouts from '../component/Abouts';
import Buy from '../component/Buy';

const About = () => {
    return (
        <>
            <Buy />
            <Abouts />
        </>
    );
}

export default About;
