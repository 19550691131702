import React from 'react';
import Buy from '../component/Buy';
import Contact from '../component/Contact';
import Effect from '../component/Effect';

const Bulk = () => {
    return (
        <>
          <Effect />
            <Contact />
            <Buy />
        </>
          
    
    );
}

export default Bulk;
